console.log('%cMade with ' + '%c♥' + '%c by %cFelix Tesche%c\n', 'font-size:16px;color:#455A64', 'font-size:16px;color:#EC407A', 'font-size:16px;color:#455A64', 'font-size:16px;color:#455A64; font-weight:bold;', 'color:#EC407A');


/*Needed for real page!*/
/*
function navigation() {
    console.log('navigation');
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
        x.className += " responsive";
    } else {
        x.className = "topnav";
    }
}*/

// TODO: Detect children clicked and so on
$('.event-card').click(function() {
    console.log('click confirm');
    $(this).toggleClass('card-active');
});


var lastFixPos = 0;
var lastScrollTop = 0;
var thresholdDown = 500;
var thresholdUp = 100;

$(window).on('scroll', function() {
    var st = $(this).scrollTop();
    var diff = Math.abs($(window).scrollTop() - lastFixPos);

    if (st > lastScrollTop) {
        if (diff > thresholdDown) {
            $('nav').addClass('nav-hide');
            lastFixPos = $(window).scrollTop();
        }
    } else {
        if (diff > thresholdUp) {
            $('nav').removeClass('nav-hide');
            lastFixPos = $(window).scrollTop();
        }
    }
    lastScrollTop = st;
});

$('#impressum').click(function() {
    $('.impressum-wrapper').toggleClass('invisible');
});